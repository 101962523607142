import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
console.log("v1");
let isMobile = false;
if (window.innerWidth < 740) {
  isMobile = true;
}
gsap.registerPlugin(ScrollTrigger);

const Scroll = new (function () {
  let sections;
  let page;
  let main;
  let scrollTrigger;
  let scrollBg;
  let tl;
  let win;

  // Init
  this.init = () => {
    sections = document.querySelectorAll(".screen");
    page = document.querySelector(".main");
    main = document.querySelector(".main");
    win = {
      w: window.innerWidth,
      h: window.innerHeight,
    };

    this.setupTimeline();
    this.setupScrollTrigger();
    window.addEventListener("resize", this.onResize);
  };

  let screenMorning = document.querySelector(".screen__morning");
  let screenDay = document.querySelector(".screen__day");
  let screenEvening = document.querySelector(".screen__evening");
  let screenNight = document.querySelector(".screen_night");

  // Setup ScrollTrigger
  this.setupScrollTrigger = () => {
    page.style.height = this.getTotalScroll() + win.h + "px";
    let frameCount = 4;

    let endHeight = this.getTotalScroll() + win.h;
    scrollTrigger = ScrollTrigger.create({
      id: "mainScroll",
      // trigger: "main",
      animation: tl,
      pin: true,
      scrub: true,
      onRefreshInit: () => {
        document.querySelector(".pixel").classList.add("loaded");
      },
      start: "top top",
      end: "+=" + document.querySelector(".main").offsetHeight,
    });
  };

  // Setup timeline
  this.setupTimeline = () => {
    tl = gsap.timeline();
    tl.addLabel("label-initial");

    sections.forEach((section, index) => {
      const nextSection = sections[index + 1];
      if (!nextSection) return;

      tl.to(nextSection, {
        y: -1 * nextSection.offsetHeight,
        duration: nextSection.offsetHeight,
        ease: "linear",
      }).addLabel(`label${index}`);

      tl.to(".screen__morning .screen__content", { yPercent: -100 });
    });
  };

  // On resize
  this.onResize = () => {
    win = {
      w: window.innerWidth,
      h: window.innerHeight,
    };

    this.reset();
  };

  // Reset
  this.reset = () => {
    if (typeof ScrollTrigger.getById("mainScroll") === "object") {
      ScrollTrigger.getById("mainScroll").kill();
    }

    if (typeof tl === "object") {
      tl.kill();
      tl.seek(0);
    }

    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.init();
  };

  // Get total scroll
  this.getTotalScroll = () => {
    let totalScroll = 0;
    sections.forEach((section) => {
      totalScroll += section.offsetHeight;
    });
    totalScroll -= win.h;
    return totalScroll;
  };
})();
setTimeout(() => {
  Scroll.init();
}, 1000);

//Backgrounds animate
let tlBgMorning_1 = gsap.timeline({
  scrollTrigger: {
    trigger: ".screen__morning",
    start: "top top",
    end: "+=" + document.querySelector(".screen__morning").offsetHeight,
    scrub: true,
  },
});
tlBgMorning_1
  .addLabel("start")
  .to(".background__day", {
    opacity: 1,
  })
  .addLabel("start")
  .to(".background__morning", {
    opacity: 0,
  })
  .addLabel("start");

let tlBgDay_1 = gsap.timeline({
  scrollTrigger: {
    trigger: ".screen__day",
    start: document.querySelector(".screen__morning").offsetHeight,
    end: "+=" + document.querySelector(".screen__day").offsetHeight,
    duration: 2,
    scrub: true,
  },
});

tlBgDay_1
  .addLabel("start")
  .to(".background__evening", {
    opacity: 1,
  })
  .addLabel("start")
  .to(".background__day", {
    opacity: 0,
  })
  .addLabel("start");

let tlBgEvening_1 = gsap.timeline({
  scrollTrigger: {
    trigger: ".screen__evening",
    start: document.querySelector(".screen__evening").offsetHeight * 2,
    end: "+=" + document.querySelector(".screen__evening").offsetHeight,
    duration: 2,
    scrub: true,
  },
});

tlBgEvening_1
  .addLabel("start")
  .to(".background__night", {
    opacity: 1,
  })
  .addLabel("start")
  .to(".background__evening", {
    opacity: 0,
  })
  .addLabel("start");

//SUN
let tlCloudMorningSun = gsap.timeline({
  scrollTrigger: {
    trigger: ".screen__morning",
    start: "top top",
    end: "+=" + document.querySelector(".screen__morning").offsetHeight,
    scrub: true,
  },
});
tlCloudMorningSun
  .addLabel("start")
  .to(".clouds__img-block_sun", {
    x: 600,
    y: -600,
  })
  .addLabel("end");

let tlCloudDaySun = gsap.timeline({
  scrollTrigger: {
    trigger: ".screen__day",
    start: document.querySelector(".screen__day").offsetHeight,
    end: "+=" + document.querySelector(".screen__day").offsetHeight,
    scrub: true,
  },
});
tlCloudDaySun
  .addLabel("start")
  .to(".clouds__img-block_sun", {
    x: 1230,
    y: -570,
  })
  .addLabel("start");

let tlCloudEveningSun = gsap.timeline({
  scrollTrigger: {
    trigger: ".screen__evening",
    start: document.querySelector(".screen__evening").offsetHeight * 2,
    end: "+=" + document.querySelector(".screen__evening").offsetHeight,
    scrub: true,
  },
});
tlCloudEveningSun
  .addLabel("start")
  .to(".clouds__img-block_sun", {
    x: 1920,
    y: 250,
  })
  .addLabel("start");

let tlCloudNightSun = gsap.timeline({
  scrollTrigger: {
    trigger: ".screen__night",
    start: document.querySelector(".screen__evening").offsetHeight * 2.7,
    end: "+=" + document.querySelector(".screen__evening").offsetHeight,
    scrub: 0.1,
  },
});

tlCloudNightSun
  .addLabel("start")
  .to(".clouds__img-block_sun", {
    x: 1920,
    y: 250,
  })
  .addLabel("start")
  .to(".clouds__img-block_sun", {
    x: 1920,
    y: -500,
  })
  .addLabel("start");

let tlCloudMorningColorSun = gsap.timeline({
  scrollTrigger: {
    trigger: ".screen__morning",
    start: "top top",
    end: "+=" + document.querySelector(".screen__morning").offsetHeight,
    scrub: true,
  },
});
tlCloudMorningColorSun
  .addLabel("start")
  .to(".clouds__img-day-sun", {
    opacity: 1,
  })
  .addLabel("start")
  .to(".clouds__img-morning-sun", {
    opacity: 0,
  })
  .addLabel("start");

// CLOUDS
let tlCloudMorning_1 = gsap.timeline({
  scrollTrigger: {
    trigger: ".screen__morning",
    start: "top top",
    end: "+=" + document.querySelector(".screen__morning").offsetHeight,
    scrub: true,
  },
});
tlCloudMorning_1
  .addLabel("start")
  .to(".clouds__img-block_1", {
    x: -400,
    y: 0,
  })
  .addLabel("end");

let tlCloudDay_1 = gsap.timeline({
  scrollTrigger: {
    trigger: ".screen__day",
    start: document.querySelector(".screen__day").offsetHeight,
    end: "+=" + document.querySelector(".screen__day").offsetHeight,
    scrub: true,
  },
});
tlCloudDay_1
  .addLabel("start")
  .to(".clouds__img-block_1", {
    x: -1150,
    y: -100,
  })
  .addLabel("start");

let tlCloudEvening_1 = gsap.timeline({
  scrollTrigger: {
    trigger: ".screen__evening",
    start: document.querySelector(".screen__evening").offsetHeight * 2,
    end: "+=" + document.querySelector(".screen__evening").offsetHeight,
    scrub: true,
  },
});
tlCloudEvening_1
  .addLabel("start")
  .to(".clouds__img-block_1", {
    x: -1200,
    y: -50,
  })
  .addLabel("start");

let tlCloudNight_1 = gsap.timeline({
  scrollTrigger: {
    trigger: ".screen__night",
    start: document.querySelector(".screen__evening").offsetHeight * 2.7,
    end: "+=" + document.querySelector(".screen__evening").offsetHeight,
    scrub: 0.1,
  },
});

tlCloudNight_1
  .addLabel("start")
  .to(".clouds__img-block_1", {
    x: -1200,
    y: -50,
  })
  .addLabel("start")
  .to(".clouds__img-block_1", {
    x: -1200,
    y: -500,
    opacity: 0,
  })
  .addLabel("start");

let tlCloudMorning_2 = gsap.timeline({
  scrollTrigger: {
    trigger: ".screen__morning",
    start: "top top",
    end: "+=" + document.querySelector(".screen__morning").offsetHeight,
    scrub: true,
  },
});
tlCloudMorning_2
  .addLabel("start")
  .to(".clouds__img-block_2", {
    x: -250,
    y: 0,
  })
  .addLabel("end");

let tlCloudDay_2 = gsap.timeline({
  scrollTrigger: {
    trigger: ".screen__day",
    start: document.querySelector(".screen__day").offsetHeight,
    end: "+=" + document.querySelector(".screen__day").offsetHeight,
    scrub: true,
  },
});
tlCloudDay_2
  .addLabel("start")
  .to(".clouds__img-block_2", {
    x: -620,
    y: -20,
  })
  .addLabel("start");

let tlCloudEvening_2 = gsap.timeline({
  scrollTrigger: {
    trigger: ".screen__evening",
    start: document.querySelector(".screen__evening").offsetHeight * 2,
    end: "+=" + document.querySelector(".screen__evening").offsetHeight,
    scrub: true,
  },
});

tlCloudEvening_2
  .addLabel("start")
  .to(".clouds__img-block_2", {
    x: -820,
    y: 0,
  })
  .addLabel("start");

let tlCloudNight_2 = gsap.timeline({
  scrollTrigger: {
    trigger: ".screen__night",
    start: document.querySelector(".screen__evening").offsetHeight * 2.7,
    end: "+=" + document.querySelector(".screen__evening").offsetHeight,
    scrub: 0.1,
  },
});
tlCloudNight_2
  .addLabel("start")
  .to(".clouds__img-block_2", {
    x: -820,
    y: 0,
  })
  .addLabel("start")
  .to(".clouds__img-block_2", {
    x: -820,
    y: -500,
    opacity: 0,
  })
  .addLabel("start");

let tlCloudMorning_3 = gsap.timeline({
  scrollTrigger: {
    trigger: ".screen__morning",
    start: "top top",
    end: "+=" + document.querySelector(".screen__morning").offsetHeight,
    scrub: true,
  },
});
tlCloudMorning_3
  .addLabel("start")
  .to(".clouds__img-block_3", {
    x: 250,
    y: 0,
  })
  .addLabel("end");

let tlCloudDay_3 = gsap.timeline({
  scrollTrigger: {
    trigger: ".screen__day",
    start: document.querySelector(".screen__day").offsetHeight,
    end: "+=" + document.querySelector(".screen__day").offsetHeight,
    scrub: true,
  },
});
tlCloudDay_3
  .addLabel("start")
  .to(".clouds__img-block_3", {
    x: 700,
    y: 0,
  })
  .addLabel("start");

let tlCloudEvening_3 = gsap.timeline({
  scrollTrigger: {
    trigger: ".screen__evening",
    start: document.querySelector(".screen__evening").offsetHeight * 2,
    end: "+=" + document.querySelector(".screen__evening").offsetHeight,
    scrub: true,
  },
});
tlCloudEvening_3
  .addLabel("start")
  .to(".clouds__img-block_3", {
    x: 900,
    y: 30,
  })
  .addLabel("start");

let tlCloudNight_3 = gsap.timeline({
  scrollTrigger: {
    trigger: ".screen__night",
    start: document.querySelector(".screen__evening").offsetHeight * 2.7,
    end: "+=" + document.querySelector(".screen__evening").offsetHeight,
    scrub: 0.1,
  },
});
tlCloudNight_3
  .addLabel("start")
  .to(".clouds__img-block_3", {
    x: 900,
    y: 30,
  })
  .addLabel("start")
  .to(".clouds__img-block_3", {
    x: 900,
    y: -500,
    opacity: 0,
  })
  .addLabel("start");

let tlCloudMorning_4 = gsap.timeline({
  scrollTrigger: {
    trigger: ".screen__morning",
    start: "top top",
    end: "+=" + document.querySelector(".screen__morning").offsetHeight,
    scrub: true,
  },
});
tlCloudMorning_4
  .addLabel("start")
  .to(".clouds__img-block_4", {
    x: 525,
    y: -200,
  })
  .addLabel("end");

let tlCloudDay_4 = gsap.timeline({
  scrollTrigger: {
    trigger: ".screen__day",
    start: document.querySelector(".screen__day").offsetHeight,
    end: "+=" + document.querySelector(".screen__day").offsetHeight,
    scrub: true,
  },
});
tlCloudDay_4
  .addLabel("start")
  .to(".clouds__img-block_4", {
    x: 1300,
    y: -100,
  })
  .addLabel("start");

let tlCloudEvening_4 = gsap.timeline({
  scrollTrigger: {
    trigger: ".screen__evening",
    start: document.querySelector(".screen__evening").offsetHeight * 2,
    end: "+=" + document.querySelector(".screen__evening").offsetHeight,
    // duration: 5,
    scrub: true,
  },
});
tlCloudEvening_4
  .addLabel("start")
  // .from(".clouds__img-block_4", {
  //   // x: 1300,
  //   // y: -100,
  // })
  // .addLabel("start")
  .to(".clouds__img-block_4", {
    x: 1400,
    y: -200,
  })
  .addLabel("start");

let tlCloudNight_4 = gsap.timeline({
  scrollTrigger: {
    trigger: ".screen__night",
    start: document.querySelector(".screen__evening").offsetHeight * 2.7,
    end: "+=" + document.querySelector(".screen__evening").offsetHeight,
    scrub: 0.1,
  },
});
tlCloudNight_4
  .addLabel("start")
  .to(".clouds__img-block_4", {
    x: 1400,
    y: -200,
  })
  .addLabel("start")
  .to(".clouds__img-block_4", {
    x: 1400,
    y: -800,
    opacity: 0,
  })
  .addLabel("start");

//CLOUD COLOR
let tlCloudMorningColor_1 = gsap.timeline({
  scrollTrigger: {
    trigger: ".screen__morning",
    start: "top top",
    end: "+=" + document.querySelector(".screen__morning").offsetHeight,
    scrub: true,
  },
});
tlCloudMorningColor_1
  .addLabel("start")
  .to(".clouds__img-day-1", {
    opacity: 1,
  })
  .addLabel("start")
  .to(".clouds__img-morning-1", {
    opacity: 0,
  })
  .addLabel("start");

let tlCloudDayColor_1 = gsap.timeline({
  scrollTrigger: {
    trigger: ".screen__day",
    start: document.querySelector(".screen__day").offsetHeight,
    end: "+=" + document.querySelector(".screen__day").offsetHeight,
    scrub: true,
  },
});
tlCloudDayColor_1
  .addLabel("start")
  .to(".clouds__img-evening-1", {
    opacity: 1,
  })
  .addLabel("start")
  .to(".clouds__img-day-1", {
    opacity: 0,
  })
  .addLabel("start");

let tlCloudEveningColor_1 = gsap.timeline({
  scrollTrigger: {
    trigger: ".screen__evening",
    start: document.querySelector(".screen__evening").offsetHeight * 2,
    end: "+=" + document.querySelector(".screen__evening").offsetHeight,
    scrub: true,
  },
});
tlCloudEveningColor_1
  .addLabel("start")
  .to(".clouds__img-night-1", {
    opacity: 1,
  })
  .addLabel("start")
  .to(".clouds__img-evening-1", {
    opacity: 0,
  })
  .addLabel("start");

let tlCloudMorningColor_2 = gsap.timeline({
  scrollTrigger: {
    trigger: ".screen__morning",
    start: "top top",
    end: "+=" + document.querySelector(".screen__morning").offsetHeight,
    scrub: true,
  },
});
tlCloudMorningColor_2
  .addLabel("start")
  .to(".clouds__img-day-2", {
    opacity: 1,
  })
  .addLabel("start")
  .to(".clouds__img-morning-2", {
    opacity: 0,
  })
  .addLabel("start");

let tlCloudDayColor_2 = gsap.timeline({
  scrollTrigger: {
    trigger: ".screen__day",
    start: document.querySelector(".screen__day").offsetHeight,
    end: "+=" + document.querySelector(".screen__day").offsetHeight,
    scrub: true,
  },
});
tlCloudDayColor_2
  .addLabel("start")
  .to(".clouds__img-evening-2", {
    opacity: 1,
  })
  .addLabel("start")
  .to(".clouds__img-day-2", {
    opacity: 0,
  })
  .addLabel("start");

let tlCloudEveningColor_2 = gsap.timeline({
  scrollTrigger: {
    trigger: ".screen__evening",
    start: document.querySelector(".screen__evening").offsetHeight * 2,
    end: "+=" + document.querySelector(".screen__evening").offsetHeight,
    scrub: true,
  },
});
tlCloudEveningColor_2
  .addLabel("start")
  .to(".clouds__img-night-2", {
    opacity: 1,
  })
  .addLabel("start")
  .to(".clouds__img-evening-2", {
    opacity: 0,
  })
  .addLabel("start");

let tlCloudMorningColor_3 = gsap.timeline({
  scrollTrigger: {
    trigger: ".screen__morning",
    start: "top top",
    end: "+=" + document.querySelector(".screen__morning").offsetHeight,
    scrub: true,
  },
});
tlCloudMorningColor_3
  .addLabel("start")
  .to(".clouds__img-day-3", {
    opacity: 1,
  })
  .addLabel("start")
  .to(".clouds__img-morning-3", {
    opacity: 0,
  })
  .addLabel("start");

let tlCloudDayColor_3 = gsap.timeline({
  scrollTrigger: {
    trigger: ".screen__day",
    start: document.querySelector(".screen__day").offsetHeight,
    end: "+=" + document.querySelector(".screen__day").offsetHeight,
    scrub: true,
  },
});
tlCloudDayColor_3
  .addLabel("start")
  .to(".clouds__img-evening-3", {
    opacity: 1,
  })
  .addLabel("start")
  .to(".clouds__img-day-3", {
    opacity: 0,
  })
  .addLabel("start");

let tlCloudEveningColor_3 = gsap.timeline({
  scrollTrigger: {
    trigger: ".screen__evening",
    start: document.querySelector(".screen__evening").offsetHeight * 2,
    end: "+=" + document.querySelector(".screen__evening").offsetHeight,
    scrub: true,
  },
});
tlCloudEveningColor_3
  .addLabel("start")
  .to(".clouds__img-night-3", {
    opacity: 1,
  })
  .addLabel("start")
  .to(".clouds__img-evening-3", {
    opacity: 0,
  })
  .addLabel("start");

let tlCloudMorningColor_4 = gsap.timeline({
  scrollTrigger: {
    trigger: ".screen__morning",
    start: "top top",
    end: "+=" + document.querySelector(".screen__morning").offsetHeight,
    scrub: true,
  },
});
tlCloudMorningColor_4
  .addLabel("start")
  .to(".clouds__img-day-4", {
    opacity: 1,
  })
  .addLabel("start")
  .to(".clouds__img-morning-4", {
    opacity: 0,
  })
  .addLabel("start");

let tlCloudDayColor_4 = gsap.timeline({
  scrollTrigger: {
    trigger: ".screen__day",
    start: document.querySelector(".screen__day").offsetHeight,
    end: "+=" + document.querySelector(".screen__day").offsetHeight,
    scrub: true,
  },
});
tlCloudDayColor_4
  .addLabel("start")
  .to(".clouds__img-evening-4", {
    opacity: 1,
  })
  .addLabel("start")
  .to(".clouds__img-day-4", {
    opacity: 0,
  })
  .addLabel("start");

let tlCloudEveningColor_4 = gsap.timeline({
  scrollTrigger: {
    trigger: ".screen__evening",
    start: document.querySelector(".screen__evening").offsetHeight * 2,
    end: "+=" + document.querySelector(".screen__evening").offsetHeight,
    scrub: true,
  },
});
tlCloudEveningColor_4
  .addLabel("start")
  .to(".clouds__img-night-4", {
    opacity: 1,
  })
  .addLabel("start")
  .to(".clouds__img-evening-4", {
    opacity: 0,
  })
  .addLabel("start");

let tlMorning = gsap.timeline({
  scrollTrigger: {
    trigger: ".screen__morning",
    start: "top top",
    end: "+=" + document.querySelector(".screen__morning").offsetHeight / 2,
    duration: 2,
    scrub: true,
  },
});
tlMorning
  .addLabel("start")
  .to(".screen__morning .screen__content", { opacity: 0, y: -400 })
  .addLabel("end");

let tlDay = gsap.timeline({
  scrollTrigger: {
    trigger: ".screen__day",
    start: document.querySelector(".screen__day").offsetHeight / 3,
    end: "+=" + document.querySelector(".screen__day").offsetHeight,
    duration: 2,
    scrub: true,
  },
});
tlDay
  .addLabel("start")
  .from(".screen__day .screen__content", { opacity: 0, y: 400 })
  .addLabel("end")
  .to(".screen__day .screen__content", { opacity: 1, y: -400 })
  .addLabel("end");

let tlEvening = gsap.timeline({
  scrollTrigger: {
    trigger: ".screen__evening",
    start: document.querySelector(".screen__evening").offsetHeight,
    end: "+=" + document.querySelector(".screen__evening").offsetHeight,
    duration: 2,
    scrub: true,
  },
});
tlEvening
  .addLabel("start")
  .from(".screen__evening .screen__content", { opacity: 0, y: 400 })
  .addLabel("end")
  .to(".screen__evening .screen__content", { opacity: 1, y: -700 })
  .addLabel("end");

let tlNight = gsap.timeline({
  scrollTrigger: {
    trigger: ".screen__night",
    start: document.querySelector(".screen__night").offsetHeight - 500,
    end: "+=" + document.querySelector(".screen__night").offsetHeight,
    // duration: 2,
    scrub: true,
    // markers: true,
    // markers: {startColor: "green", endColor: "red", fontSize: "18px", fontWeight: "bold", indent: 200}
  },
});
tlNight
  .addLabel("start")
  .from(".bottom .item__content", { opacity: 0 })
  .addLabel("end")
  .to(".bottom .item__content", { opacity: 1 })
  .addLabel("end");

let tlBuilding = gsap.timeline({
  scrollTrigger: {
    trigger: ".building",
    // start: document.querySelector(".screen__night").offsetHeight,
    // end: "+=" + document.querySelector(".screen__night").offsetHeight,

    start: "bottom bottom",
    // start: "+=" + document.querySelector(".screen__evening").offsetHeight,
    end: "+=100",
    // end: "+=" + document.querySelector(".screen__night").offsetHeight,
    pin: true,
    scrub: 0.5,
    // markers: true,
    onEnter: () => {},
    onLeave: () => {},
  },
});
tlBuilding
  .addLabel("start")
  .from(".building", { opacity: 1, scale: 1 })
  .addLabel("end")
  .to(".building", { opacity: 0, scale: 1.1 })
  .addLabel("end");

let tlWindows = gsap.timeline({
  scrollTrigger: {
    trigger: ".building",
    // start: document.querySelector(".screen__night").offsetHeight,
    // end: "+=" + document.querySelector(".screen__night").offsetHeight,
    start: "bottom bottom",
    // start: document.querySelector(".screen__night").offsetHeight * 1.85,
    // start: "+=" + document.querySelector(".screen__evening").offsetHeight,
    end: "+=200",
    // pin: true,
    scrub: true,
    onEnter: () => {
      document.querySelector(".windows").classList.add("active");
    },
    onLeaveBack: () => {
      document.querySelector(".windows").classList.remove("active");
    },
    onLeave: () => {
      document.querySelector(".windows").classList.remove("active");
    },
    // markers: true,
  },
});
tlWindows
  .addLabel("start")
  .from(".windows", { opacity: 0, scale: 0.8 })
  .addLabel("end")
  .to(".windows", { opacity: 1, scale: 1 })
  .addLabel("end");

// if (isMobile) {
// } else {
//   let tlBuilding = gsap.timeline({
//     scrollTrigger: {
//       trigger: ".screen__night",
//       // start: document.querySelector(".screen__night").offsetHeight,
//       // end: "+=" + document.querySelector(".screen__night").offsetHeight,
//       start: document.querySelector(".screen__night").offsetHeight * 1.62,
//       // start: "+=" + document.querySelector(".screen__evening").offsetHeight,
//       end: "+=" + document.querySelector(".screen__evening").offsetHeight / 3,
//       // pin: true,
//       scrub: 0.5,
// markers: true,
//     },
//   });
//   tlBuilding
//     .addLabel("start")
//     .from(".bottom", { opacity: 1, scale: 1 })
//     .addLabel("end")
//     .to(".bottom", { opacity: 0, scale: 1.1 })
//     .addLabel("end");

//   let tlWindows = gsap.timeline({
//     scrollTrigger: {
//       trigger: ".screen__night",
//       // start: document.querySelector(".screen__night").offsetHeight,
//       // end: "+=" + document.querySelector(".screen__night").offsetHeight,
//       start: document.querySelector(".screen__night").offsetHeight,
//       // start: "+=" + document.querySelector(".screen__evening").offsetHeight,
//       end: "+=" + document.querySelector(".screen__evening").offsetHeight / 3,
//       // pin: true,
//       scrub: true,
// markers: true,
//     },
//   });
//   tlWindows
//     .addLabel("start")
//     .from(".windows", { opacity: 0, scale: 0.8 })
//     .addLabel("end")
//     .to(".windows", { opacity: 1, scale: 1 })
//     .addLabel("end");
// }

let swiper = new Swiper(".swiper", {
  slidesPerView: 1,
  spaceBetween: 30,
  loop: true,
  effect: "fade",
  // modules: [Navigation, Pagination],

  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
});

let page = document.querySelector("body");
let main = document.querySelector(".main");
let controls = document.querySelector(".controls");
let windowLink = document.querySelector(".building__office-link");
let buildingSection = document.querySelector(".building");
let windowsSection = document.querySelector(".windows");
let persons = document.querySelectorAll(".building__person");
let slider = document.querySelector(".slider");
let modalClose = document.querySelectorAll(".modal__close");
let windows = document.querySelectorAll(".windows__image-link");

let modals = document.querySelectorAll(".modal");

const welldone = document.querySelector(".welldone");

windowLink.addEventListener("click", (e) => {
  // windowsSection.classList.add("show");
  // main.classList.add("fixed-height");
  // buildingSection.classList.add("hide");
  window.scrollTo(0, document.body.scrollHeight);
});

persons.forEach((person, index) => {
  let speed = 1;
  person.addEventListener("click", () => {
    slider.classList.add("show");
    if (slider.classList.contains("show")) {
      windowsSection.classList.add("hidden");
    } else {
      windowsSection.classList.remove("hidden");
    }

    page.style.overflow = "hidden";
    controls.classList.add("hide");
    main.classList.remove("fixed-height");
    buildingSection.classList.add("hide");
    swiper.slideTo(index, speed, false);
  });
});

windows.forEach((window, index) => {
  let speed = 1;
  window.addEventListener("click", () => {
    slider.classList.add("show");
    if (slider.classList.contains("show")) {
      windowsSection.classList.add("hidden");
    } else {
      windowsSection.classList.remove("hidden");
    }

    main.classList.remove("fixed-height");
    page.style.overflowY = "hidden";
    controls.classList.add("hide");
    buildingSection.classList.add("hide");
    windowsSection.classList.remove("show");
    swiper.slideTo(index, speed, false);
  });
});

modalClose.forEach((close) => {
  close.addEventListener("click", (e) => {
    slider.classList.remove("show");
    buildingSection.classList.remove("hide");
    page.style.overflowY = "visible";
    controls.classList.remove("hide");
    welldone.classList.remove("show");
    if (slider.classList.contains("show")) {
      windowsSection.classList.add("hidden");
    } else {
      windowsSection.classList.remove("hidden");
    }
    modals.forEach((item) => {
      item.classList.remove("show");
      scrollStopped.value = true;
    });
  });
});

let fixedScreens = document.querySelectorAll(".screen-fixed");
let screenNight = document.querySelector(".screen__night");
let itemsHeight = 0;
fixedScreens.forEach((item) => {
  itemsHeight += item.offsetHeight;
  screenNight.style.marginTop = itemsHeight + "px";
});

let controlsElementZoom = document.querySelector(".controls__element-zoom");
const pixel = document.querySelector(".pixel");

controlsElementZoom.addEventListener("click", () => {
  controlsElementZoom.classList.toggle("zoom");
  pixel.classList.toggle("zoom");
});

function toggleAudio() {
  let isPlaying = false;
  let audio;
  if (window.location.href.indexOf("kasperskyspecial.com") > -1) {
    audio = new Audio("https://kasperskyspecial.com/audio/audio_eng.mp3");
  } else {
    audio = new Audio("https://kasperskyspecial.ru/audio/audio_rus.mp3");
  }

  function handleClick() {
    if (isPlaying) {
      stopAudio();
    } else {
      playAudio();
    }
  }

  function playAudio() {
    isPlaying = true;
    audio.play();
  }

  function stopAudio() {
    isPlaying = false;
    audio.pause();
    // audio.currentTime = 0;
  }

  let controlsElementSound = document.querySelector(".controls__element-sound");
  controlsElementSound.addEventListener("click", handleClick);
}

// Объект-флаг для отслеживания состояния прекращения прокрутки
const scrollStopped = {
  value: false,
};
let scrollItems;
function startSliderScroll() {
  document.querySelector(".windows__image.windows__image-link").click();
  if (isMobile) {
    scrollItems = document.querySelectorAll(".slider .swiper-slide");
    // console.log('scrollItems', scrollItems);
  } else {
    scrollItems = document.querySelectorAll(".slider .slider__info-main");
  }

  function scrollBlocks(blocks, currentIndex) {
    // Проверяем, достигли ли конца текущего блока
    console.log("blocks", blocks);
    if (
      blocks[currentIndex].scrollTop + blocks[currentIndex].clientHeight ===
      blocks[currentIndex].scrollHeight - 1
    ) {
      document.querySelector(".slider .swiper-button-next").click();
      // Прокручиваем текущий блок в начало
      blocks[currentIndex].scrollTop = 0;

      // Вычисляем индекс следующего блока
      const nextIndex = (currentIndex + 1) % blocks.length;

      // Задержка перед прокруткой следующего блока (в миллисекундах)
      const scrollDelay = 2000;

      // Ждем заданную задержку и затем прокручиваем следующий блок
      setTimeout(() => {
        if (shouldStopScrolling()) {
          // Прерываем выполнение функции
          return;
        }
        console.log("check2");
        scrollBlocks(blocks, nextIndex);
      }, scrollDelay);
    } else {
      // Прокручиваем текущий блок на небольшую величину
      blocks[currentIndex].scrollBy(0, 0.5);

      // Задержка между прокрутками (в миллисекундах)
      const scrollInterval = 15;

      // Продолжаем прокрутку текущего блока после небольшой задержки
      setTimeout(() => {
        if (shouldStopScrolling()) {
          // Прерываем выполнение функции
          return;
        }
        console.log("check3");
        scrollBlocks(blocks, currentIndex);
      }, scrollInterval);
    }
  }
  setTimeout(() => {
    if (shouldStopScrolling()) {
      // Прерываем выполнение функции
      return;
    }
    console.log("check1");
    scrollBlocks(scrollItems, 0);
  }, "2000");
}
// Функция для проверки условия прерывания прокрутки
function shouldStopScrolling() {
  return scrollStopped.value;
}
function toggleSmoothScroll() {
  let isScrolling = false; // Флаг для отслеживания состояния прокрутки
  scrollStopped.value = false;
  function handleClick() {
    if (isScrolling) {
      // Прокрутка уже запущена, останавливаем её
      stopSmoothScroll();
    } else {
      // Прокрутка не запущена, запускаем её
      startSmoothScroll();
    }
  }

  function startSmoothScroll() {
    // Запуск прокрутки
    isScrolling = true;
    smoothScroll();
  }

  function stopSmoothScroll() {
    // Остановка прокрутки
    isScrolling = false;
    clearInterval(timerId);
  }

  let timerId;

  function smoothScroll() {
    timerId = setInterval(function () {
      window.scrollBy(0, 0.5);
      if (
        window.pageYOffset + window.innerHeight >=
        document.body.scrollHeight
      ) {
        clearInterval(timerId);
        stopSmoothScroll();
        startSliderScroll();
      }
    }, 10);
  }

  const controlsElementPlay = document.querySelector(".controls__element-play");
  controlsElementPlay.addEventListener("click", handleClick);
}
const contentHidden = document.querySelectorAll(".content-hidden");

if (contentHidden) {
  contentHidden.forEach((item) => {
    setTimeout(() => {
      item.classList.add("visible");
    }, "1000");
  });
}
// window.addEventListener("DOMContentLoaded", () => {
toggleAudio();
toggleSmoothScroll();
// });

//счётчик слайдов
let doneSlides = false;
swiper.on("slideChange", function (e) {
  if (doneSlides) {
    setTimeout(() => {
      welldone.classList.add("show");
    }, "2000");
  }
  incrementCounter(swiper.realIndex);
});
const totalSlides = 4;
let viewedSlides = new Set();

function incrementCounter(slideNumber) {
  if (viewedSlides.size === totalSlides) {
    doneSlides = false;
    return; // Завершаем функцию, если все слайды уже просмотрены
  }

  viewedSlides.add(slideNumber);

  if (viewedSlides.size === totalSlides) {
    doneSlides = true;
    return;
  }
}
